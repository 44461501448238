// src/QrScanner.js
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QrScannerComponent = () => {
  const [data, setData] = useState('No result');
  const [facingMode, setFacingMode] = useState('environment'); // 'environment' for back camera

  const handleScan = (result) => {
    if (result) {
      setData(result.text || 'No data found');
      toast.success(`Scanned Data: ${result.text || 'No data found'}`);
    }
  };

  const handleError = (err) => {
    console.error(err);
    toast.error(`Error: ${err.message}`);
  };

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === 'environment' ? 'user' : 'environment'));
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>QR Code Scanner</h1>
      <button onClick={toggleFacingMode}>
        Switch Camera
      </button>
      <QrReader
delay={ 300 }
onError={ handleError }
onResult={ handleScan }
constraints={ {facingMode: 'environment'} }/>
      <p>{data}</p>
      <ToastContainer />
    </div>
  );
};

export default QrScannerComponent;
